<template>
	<ViewLayout>
		<template #header-title>
			Sender Email Addresses
		</template>
		<template #header-caption>
			Add, edit and manage sender email addresses
		</template>
		<template #header-after>
			<Tabs
				:tab.sync="tab"
				:tabs="filteredTabs"
			/>
		</template>
		<template #content>
			<TabItems
				:tab="tab"
				:tabs="tabs"
			>
				<template #0>
					<SenderEmailAddressesTab />
				</template>
				<template
					v-if="hasSeeVerifyDomainAndEmailPermission"
					#1
				>
					<SenderEmailDomainsTab />
				</template>
			</TabItems>
		</template>
	</ViewLayout>
</template>

<script>
import { mapGetters } from 'vuex'
import Tabs from '../../../../../../shared/components/tabs.vue'
import TabItems from '../../../../../../shared/components/tab-items.vue'
import ViewLayout from '../../../../../../shared/layouts/view-layout.vue'
import SenderEmailAddressesTab from './sender-email-addresses-tab.vue'
import SenderEmailDomainsTab from './sender-email-domains-tab.vue'
import { VERIFY_DOMAIN_OR_EMAIL_FULL_PERMISSIONS } from '../../../../../../shared/permissions/admin-portal-permissions.js'
export default {
	components: { ViewLayout, Tabs, TabItems, SenderEmailAddressesTab, SenderEmailDomainsTab },
	data () {
		return {
			tabs: [
				{ title: 'Sender Email Addresses', slot: '0', domainsTab: false },
				{ title: 'Verify Domains & Email Addresses', slot: '1', domainsTab: true }
			],
			tab: '0'
		}
	},
	computed: {
		...mapGetters({
			checkPermission: 'auth/productAreaPermission'
		}),
		hasSeeVerifyDomainAndEmailPermission () {
			return this.checkPermission(VERIFY_DOMAIN_OR_EMAIL_FULL_PERMISSIONS)
		},
		filteredTabs () {
			return this.tabs
				.filter(({ domainsTab }) => !domainsTab || domainsTab && this.hasSeeVerifyDomainAndEmailPermission)
		}
	}
}
</script>
