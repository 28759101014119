import { format } from 'date-fns'
import { DATE_FORMAT } from '../date-formatting.js'
import { configApi } from '../http-client.js'

export const getSenderEmailAddresses = () => {
	return configApi.get('/api/senderEmails').then(({ data: { senderEmails } }) => JSON.parse(JSON.stringify(({
		data: senderEmails.map(senderEmail => ({
			...senderEmail,
			senderEmailAddressId: senderEmail.emailID,
			fromDisplayName: senderEmail.fromDisplay,
			fromAddress: senderEmail.fromAddress,
			replyAddress: senderEmail.replyAddress,
			spfValid: senderEmail.spfValid,
			createdDate: format(new Date(senderEmail.dateAdded), DATE_FORMAT)
		}))
	}))))
}

export const deleteSenderEmailAddress = senderEmailAddressId => configApi.delete(`/api/senderEmails/${senderEmailAddressId}`)

export const upsertSenderEmailAddress = upsertSenderEmailRequest => {
	return configApi.post('/api/senderEmails/Upsert', upsertSenderEmailRequest)
}

export const verifySenderEmailRequest = verifySenderEmailID => {
	return configApi.post('/api/senderEmails/Verify', verifySenderEmailID)
}

export const createDomainOrEmail = createSenderEmail => {
	return configApi.post('/api/senderEmails/Create', createSenderEmail)
}

export const getAwsIdentities = () => {
	return configApi.get('/api/senderEmails/GetAwsIdentities').then(({ data: { awsIdentities } }) => JSON.parse(JSON.stringify(({
		data: awsIdentities.map(awsIdentity => ({
			...awsIdentity,
			displayTokens: awsIdentity.returnTokens?.map(token => ({
				displayToken: token.returnToken,
				displayCName: token.returnToken + '._domainkey.' + awsIdentity.identity,
				displayCNameValue: token.returnToken + '.dkim.amazonses.com',
				displayCNameTitle: 'CNAME'
			}))
		}))
	}))))
}
