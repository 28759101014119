<template>
	<div class="cassie-vertical-md mt-1">
		<FilterBar
			:search-query.sync="searchQuery"
			search-query-label="Identity"
			hide-brand-filter
		>
			<template #tooltip>
				<v-tooltip
					right
				>
					<template #activator="{ on }">
						<v-icon
							left
							v-on="on"
							@click.stop="showInformation = true"
						>
							mdi-information
						</v-icon>
					</template>
					<span>
						Click to expand
					</span>
				</v-tooltip>
			</template>
			<template #action>
				<PrimaryActionButton
					v-if="!readOnlyPermissions && canCreateLimit"
					@click="addSenderEmailDomains"
				>
					<v-icon left>
						mdi-plus
					</v-icon>
					Create
				</PrimaryActionButton>
			</template>
		</FilterBar>
		<SectionCard>
			<template #title>
				Domains and email addresses
			</template>
			<template #body>
				<DataTable
					:items="tableData"
					:headers="tableHeaders"
					sort-by="id"
					sort-desc
					@click:row="showTokensModal"
				>
					<template #item.awsIdentityType="{ item }">
						{{ awsIdentityTypeText(item.awsIdentityType) }}
					</template>
					<template #item.identityVerified="{ item }">
						&nbsp;{{ item.identityVerified ? 'Verified' : 'Unverified' }}
					</template>
					<template #item.action="{ item }">
						<IconButton
							tooltip-text="Open Tokens"
							@click="showTokensModal(item)"
						>
							mdi-eye
						</IconButton>
					</template>
				</DataTable>
			</template>
		</SectionCard>
		<validation-observer #default="{ handleSubmit, reset }">
			<Modal
				v-if="formData"
				width="800px"
			>
				<template #modal-title>
					Add domain or email address
				</template>
				<template #modal-content>
					<div class="cassie-vertical-md">
						<RadioGroup
							:radio-group-value.sync="formData.domainOrEmailAddress"
							:radios="domainOrEmailAddressRadios"
							image-radio-group
							@update:radioGroupValue="reset()"
						/>
						<TextField
							v-model="formData.emailIdentity"
							:tooltip-text="formData.domainOrEmailAddress ? 'Please input the domain you wish to send emails from - The DKIM verification will use 2048 bit signing key length as recommended by AWS' : 'When you verify an email address, Amazon SES sends an email to the address. Your email address is verified as soon as you follow the link in the verification email. DKIM Authentication can only be set at the domain level'"
							:label="formData.domainOrEmailAddress ? 'Domain *' : 'Email address *'"
							:rules="formData.domainOrEmailAddress ? 'required' : 'required|email'"
						/>
					</div>
				</template>
				<template #modal-footer>
					<SecondaryActionButton @click="closeSubmitModal(reset)">
						Cancel
					</SecondaryActionButton>
					<PrimaryActionButton
						:disabled="formData.readOnly"
						@click="handleSubmit(submit)"
					>
						Create
					</PrimaryActionButton>
				</template>
			</Modal>
		</validation-observer>
		<Modal
			v-if="showInformation"
			width="600px"
		>
			<template #modal-title>
				Add domain or email address
			</template>
			<template #modal-content>
				<div class="cassie-vertical-md">
					<p>
						This is the process of verifying an email identity. An identity is an email address or domain that you use when you send email.  Before you can use an identity to send email, you first have to verify it.  By verifying an identity, you demonstrate that you're the owner of the identity, and that you've given Cassie's AWS Services consent to send email from the identity.
					</p>
					<p>
						Verifying a Domain
						<br><br>
						When you verify a domain, this operation provides a set of DKIM tokens, which you can convert into CNAME tokens.  You add these CNAME tokens to the DNS configuration for your domain.  Your domain is verified when Amazon SES detects these records in the DNS configuration for your domain.  For some DNS providers, it can take 72 hours or more to complete the domain verification process.
					</p>
					<p>
						Verifying an Email Address
						<br><br>
						When you verify an email address, Amazon SES sends an email to the address. Your email address is verified as soon as you follow the link in the verification email.
					</p>
				</div>
			</template>
			<template #modal-footer>
				<SecondaryActionButton @click="showInformation = false">
					Close
				</SecondaryActionButton>
			</template>
		</Modal>
		<Modal
			v-if="showTokens"
			width="70%"
		>
			<template #modal-title>
				Tokens
			</template>
			<template #modal-content>
				<DataTable
					:items="tokensData"
					:headers="tokensHeaders"
				>
					<template #item.returnToken="{ item }">
						{{ item.returnToken }}
						<IconButton
							tooltip-text="Copy to Clipboard"
							@click="copyToClipboard(item.returnToken)"
						>
							mdi-content-copy
						</IconButton>
					</template>
				</DataTable>
				<p>
					You can use above tokens that are generated by this operation to create CNAME records.
				</p>
				<p>
					When Amazon SES detects that you've added these records to the DNS configuration for a domain, you can start sending emails from that domain.
				</p>
				<p>
					You can start sending email even if you haven't added the TXT record provided by the VerifyDomainIdentity operation to the DNS configuration for your domain.
				</p>
				<DataTable
					:items="tokensCnameData"
					:headers="tokensAsCname"
				>
					<template #item.displayCName="{ item }">
						{{ item.displayCName }}
						<IconButton
							tooltip-text="Copy to Clipboard"
							@click="copyToClipboard(item.displayCName)"
						>
							mdi-content-copy
						</IconButton>
					</template>
					<template #item.displayCNameValue="{ item }">
						{{ item.displayCNameValue }}
						<IconButton
							tooltip-text="Copy to Clipboard"
							@click="copyToClipboard(item.displayCNameValue)"
						>
							mdi-content-copy
						</IconButton>
					</template>
				</DataTable>
			</template>
			<template #modal-footer>
				<SecondaryActionButton @click="showTokens = false">
					Close
				</SecondaryActionButton>
			</template>
		</Modal>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import FilterBar from '../../../../../../shared/components/filter-bar.vue'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import Modal from '../../../../../../shared/components/modal.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import { VERIFY_DOMAIN_OR_EMAIL_FULL_PERMISSIONS } from '../../../../../../shared/permissions/admin-portal-permissions.js'
import {
	getAwsIdentities,
	createDomainOrEmail
} from '../../../../../../shared/utils/api/sender-email-addresses.js'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
import TextField from '../../../../../../shared/components/text-field.vue'
import RadioGroup from '../../../../../../shared/components/radio-group.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import { senderEmailsIdentityLimit } from '../../../../../../shared/state/config-keys.js'
export default {
	components: { TextField, SecondaryActionButton, Modal, DataTable, PrimaryActionButton, SectionCard, FilterBar, RadioGroup, IconButton },
	setup () {
		return {
			showSnackbar,
			senderEmailsIdentityLimit
		}
	},
	data () {
		return {
			searchQuery: null,
			formData: null,
			senderEmailDomains: [],
			showInformation: false,
			showTokens: false,
			awsIdentityTypes: [
				{ value: 1, text: 'Domain' },
				{ value: 2, text: 'Email address' }
			],
			dkimTypeRadios: [
				{ label: 'Easy DKIM route', value: true }
			],
			signingKeyLength: [
				{ value: 1, text: 'RSA_2048_BIT' }
			],
			domainOrEmailAddressRadios: [
				{ label: 'Domain', value: true },
				{ label: 'Email address', value: false }
			],
			tokensData: [],
			tokensCnameData: []
		}
	},
	computed: {
		...mapGetters({
			checkPermission: 'auth/productAreaPermission'
		}),
		tableHeaders () {
			return [
				{ value: 'id', text: 'ID', width: '5%' },
				{ value: 'identity', text: 'Identity', width: '30%' },
				{ value: 'awsIdentityType', text: 'AWS Identity Type', width: '25%' },
				{ value: 'identityVerified', text: 'Identity Verified', width: '25%' },
				{ value: 'action', text: 'Action', width: '10%', sortable: false }
			]
		},
		readOnlyPermissions () {
			return !this.checkPermission(VERIFY_DOMAIN_OR_EMAIL_FULL_PERMISSIONS)
		},
		tableData () {
			return this.senderEmailDomains
				.filter(({ identity }) => !this.searchQuery || identity.toLowerCase().includes(this.searchQuery.toLowerCase()))
		},
		tokensHeaders () {
			return [
				{ value: 'returnToken', text: 'Name', width: '100%' }
			]
		},
		tokensAsCname () {
			return [
				{ value: 'displayCName', text: 'Name', width: '40%' },
				{ value: 'displayCNameTitle', text: 'Type', width: '20%' },
				{ value: 'displayCNameValue', text: 'Value', width: '40%' }
			]
		},
		canCreateLimit () {
			return (this.senderEmailDomains.length < senderEmailsIdentityLimit.value)
		}
	},
	created () {
		this.loadSenderEmailDomains()
	},
	methods: {
		async loadSenderEmailDomains () {
			const { data: senderEmailDomains } = await getAwsIdentities()
			this.senderEmailDomains = senderEmailDomains
		},
		addSenderEmailDomains () {
			this.formData = {
				domainOrEmailAddress: true,
				easyDkimRoute: true,
				emailIdentity: null,
				domainSigningPrivateKey: null,
				domainSigningSelector: null,
				nextSigningKeyLength: null
			}
		},
		closeSubmitModal (reset) {
			if (reset) {
				reset()
			}
			this.formData = null
		},
		async submit () {
			if (this.formData.domainOrEmailAddress) {
				this.formData.domainSigningPrivateKey = null
				this.formData.domainSigningSelector = null
				this.formData.nextSigningKeyLength = 'RSA_2048_BIT'
			} else {
				this.formData.domainSigningPrivateKey = null
				this.formData.domainSigningSelector = null
				this.formData.nextSigningKeyLength = null
			}
			const domainOrEmail = {
				emailIdentity: this.formData.emailIdentity,
				dkimSigningMethod: {
					domainSigningPrivateKey: this.formData.domainSigningPrivateKey,
					domainSigningSelector: this.formData.domainSigningSelector,
					nextSigningKeyLength: this.formData.nextSigningKeyLength
				}
			}
			const response = await createDomainOrEmail(domainOrEmail)
			let success = false
			if (response?.data?.displayResponse) {
				if (response.data.displayResponse.toString().toLowerCase() === 'success') {
					success = true
				}
			}
			await this.loadSenderEmailDomains()
			const domainOrEmailText = this.formData.domainOrEmailAddress ? 'domain' : 'email address'
			const message = {
				text: success ? `The ${domainOrEmailText} has been created` : `An error occurred when creating the ${domainOrEmailText}`,
				color: success ? 'success' : 'error'
			}
			showSnackbar(message)
			this.closeSubmitModal()
		},
		awsIdentityTypeText (typeId) {
			return this.awsIdentityTypes.find(identityType => identityType.value === typeId).text
		},
		showTokensModal (item) {
			this.tokensData = this.senderEmailDomains.find(senderEmailDomain => senderEmailDomain.id === item.id).returnTokens ?? []
			this.tokensCnameData = this.senderEmailDomains.find(senderEmailDomain => senderEmailDomain.id === item.id).displayTokens ?? []
			this.showTokens = true
		},
		copyToClipboard (textToCopy) {
			navigator.clipboard.writeText(textToCopy)
		}
	}
}
</script>
