<template>
	<div class="cassie-vertical-md mt-1">
		<FilterBar
			:search-query.sync="searchQuery"
			search-query-label="Display Name"
			hide-brand-filter
		>
			<template #action>
				<PrimaryActionButton
					v-if="!readOnlyPermissions"
					@click="addSenderEmailAddress"
				>
					<v-icon left>
						mdi-plus
					</v-icon>
					Create
				</PrimaryActionButton>
			</template>
		</FilterBar>
		<SectionCard>
			<template #title>
				Sender Email Addresses
			</template>
			<template #body>
				<DataTable
					:items="tableData"
					:headers="tableHeaders"
					sort-by="senderEmailAddressId"
					sort-desc
					@click:row="rowClick"
				>
					<template #item.spfValid="{ item }">
						{{ item.spfValid ? 'Yes' : 'No' }}
					</template>
					<template #item.action="{ item }">
						<v-icon
							small
							class="mr-2"
							@click.stop.prevent="rowClick(row)"
						>
							{{ readOnlyPermissions ? 'mdi-eye' : 'mdi-pencil' }}
						</v-icon>
						<v-icon
							v-if="!readOnlyPermissions"
							small
							class="mr-2"
							@click.stop.prevent="senderEmailAddressToRemove = item.senderEmailAddressId"
						>
							mdi-trash-can
						</v-icon>
					</template>
				</DataTable>
			</template>
		</SectionCard>
		<validation-observer #default="{ handleSubmit }">
			<Modal
				v-if="senderEmailAddress"
				width="600px"
			>
				<template #modal-title>
					{{ showAddModal ? 'Add' : 'Edit' }} Sender Email Address
				</template>
				<template #modal-content>
					<div class="cassie-vertical-md">
						<TextField
							v-model="senderEmailAddress.fromDisplayName"
							:disabled="readOnlyPermissions"
							tooltip-text="The name you want to be displayed in the recipients email"
							label="Display Name *"
							rules="required"
						/>
						<TextField
							v-model="senderEmailAddress.fromAddress"
							:disabled="readOnlyPermissions"
							tooltip-text="The email address to use when sending emails"
							label="Send From Email Address *"
							:rules="{ required: true, email: true }"
						/>
						<TextField
							v-model="senderEmailAddress.replyAddress"
							:disabled="readOnlyPermissions"
							tooltip-text="The email address you want replies to be sent to"
							label="Reply Email Address *"
							:rules="{ required: true, email: true }"
						/>
					</div>
				</template>
				<template #modal-footer>
					<SecondaryActionButton @click="closeSubmitModal">
						Cancel
					</SecondaryActionButton>
					<PrimaryActionButton
						:disabled="readOnlyPermissions"
						@click="handleSubmit(submit)"
					>
						{{ showAddModal ? 'Create' : 'Save' }}
					</PrimaryActionButton>
				</template>
			</Modal>
		</validation-observer>
		<ConfirmDeleteModal
			v-if="senderEmailAddressToRemove"
			:entity-name="senderEmailAddressToRemove.fromDisplayName"
			entity-type="Sender Email Address"
			@close="senderEmailAddressToRemove = null"
			@delete="deleteSenderEmailAddress"
		/>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import FilterBar from '../../../../../../shared/components/filter-bar.vue'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import ConfirmDeleteModal from '../../../../../../shared/components/confirm-delete-modal.vue'
import Modal from '../../../../../../shared/components/modal.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import { SENDER_EMAILS_MODULE_FULL_PERMISSIONS } from '../../../../../../shared/permissions/admin-portal-permissions.js'
import {
	deleteSenderEmailAddress,
	getSenderEmailAddresses,
	upsertSenderEmailAddress,
	verifySenderEmailRequest
} from '../../../../../../shared/utils/api/sender-email-addresses.js'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
import TextField from '../../../../../../shared/components/text-field.vue'
export default {
	components: { TextField, SecondaryActionButton, Modal, ConfirmDeleteModal, DataTable, PrimaryActionButton, SectionCard, FilterBar },
	setup () {
		return {
			showSnackbar
		}
	},
	data () {
		return {
			searchQuery: null,
			showAddModal: false,
			senderEmailAddress: null,
			senderEmailAddressToRemove: null,
			senderEmailAddresses: [],
			senderEmailAddressID: 0
		}
	},
	computed: {
		...mapGetters({
			checkPermission: 'auth/productAreaPermission'
		}),
		tableHeaders () {
			return [
				{ value: 'senderEmailAddressId', text: 'ID', width: '5%' },
				{ value: 'fromDisplayName', text: 'Display Name', width: '20%' },
				{ value: 'fromAddress', text: 'Send From Email Address', width: '20%' },
				{ value: 'replyAddress', text: 'Reply To Email Address', width: '20%' },
				{ value: 'spfValid', text: 'Domain Verified', width: '15%' },
				{ value: 'createdDate', text: 'Created Date', width: '10%' },
				{ value: 'action', text: 'Action', width: '10%', sortable: false }
			]
		},
		readOnlyPermissions () {
			return !this.checkPermission(SENDER_EMAILS_MODULE_FULL_PERMISSIONS)
		},
		tableData () {
			return this.senderEmailAddresses
				.filter(({ fromDisplayName }) => !this.searchQuery || fromDisplayName.toLowerCase().includes(this.searchQuery.toLowerCase()))
		}
	},
	created () {
		this.loadSenderEmailAddresses()
	},
	methods: {
		async loadSenderEmailAddresses () {
			const { data: senderEmailAddresses } = await getSenderEmailAddresses()
			this.senderEmailAddresses = senderEmailAddresses
		},
		async deleteSenderEmailAddress () {
			await deleteSenderEmailAddress(this.senderEmailAddressToRemove)
			await this.loadSenderEmailAddresses()
			this.senderEmailAddressToRemove = null
			showSnackbar('This Sender Email Address has been removed')
		},
		addSenderEmailAddress () {
			this.showAddModal = true
			this.senderEmailAddress = {
				fromDisplayName: '',
				fromAddress: '',
				replyAddress: ''
			}
		},
		closeSubmitModal () {
			this.showAddModal = null
			this.senderEmailAddress = null
		},
		rowClick (row) {
			this.showAddModal = false
			this.senderEmailAddress = JSON.parse(JSON.stringify(row))
		},
		async submit () {
			const senderEmailRequest = {
				senderEmailAddressID: this.senderEmailAddress.senderEmailAddressId,
				fromDisplayName: this.senderEmailAddress.fromDisplayName,
				fromAddress: this.senderEmailAddress.fromAddress,
				replyAddress: this.senderEmailAddress.replyAddress,
				createdBy: this.senderEmailAddress.addedBy,
				archivedby: this.senderEmailAddress.archivedby,
				brandID: this.senderEmailAddress.brandID,
				spfResult: this.senderEmailAddress.spfResult,
				spfResultText: this.senderEmailAddress.spfResultText,
				spfValid: this.senderEmailAddress.spfValid,
				spfValidationDate: this.senderEmailAddress.spfValidationDate,
				editedBy: this.senderEmailAddress.editedBy
			}
			const upsertresponse = await upsertSenderEmailAddress(senderEmailRequest)
			const verifySenderRequest = {
				verifySenderEmailID: (this.senderEmailAddress.senderEmailAddressId ?? upsertresponse.data.newSenderEmailAddressId)
			}
			await verifySenderEmailRequest(verifySenderRequest)
			await this.loadSenderEmailAddresses()
			showSnackbar(`This Sender Email Address has been ${this.showAddModal ? 'created' : 'updated'}`)
			this.closeSubmitModal()
		}
	}
}
</script>
